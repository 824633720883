<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <div class="chip closebtn">
          <img src="@/assets/images/illustration/warehouse.png" alt="Person" width="50" height="50">
          {{ warehouse }}
      </div>
      <search-bar />
    </div> 

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BTooltip
} from 'bootstrap-vue'

import DarkToggler from './components/DarkToggler.vue'
import SearchBar from './components/SearchBar.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components 
    BNavbarNav,
    DarkToggler,
    SearchBar,
    NotificationDropdown,
    UserDropdown,
    BTooltip
  },
  data: () => ({
    localStorageWarehouse: JSON.parse(localStorage.getItem("warehouse")),
    selected: '',
    warehouse: 'Admin'
  }),
  methods: {

  },
  created () {
    this.selected = this.localStorageWarehouse.id;
    if(this.localStorageWarehouse.name != ''){
      this.warehouse = this.localStorageWarehouse.name
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

}
</script>

<style lang="scss">
  .chip {
    display: inline-block;
    padding: 0 28px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    border-radius: 25px;
    color: #000;
  }

  .chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .closebtn {
    cursor: pointer;
  }
</style>
