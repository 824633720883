export default [
  {
    header: 'Operations',
    icon: 'EditIcon',
    children: [
      {
        title: 'Distribution',
        route: 'operations-distribution',
        icon: 'RepeatIcon',
      },
      {
        title: 'Activity',
        route: 'operations-activity',
        icon: 'CheckSquareIcon',
      },
      // {
      //   title: 'Transactions',
      //   route: 'operations-replenishment',
      //   icon: 'InboxIcon',
      // },
      // {
      //   title: 'Supplying',
      //   route: 'operations-supplying',
      //   icon: 'LogInIcon',
      // },
      // {
      //   title: 'Transfer',
      //   route: 'operations-transfer',
      //   icon: 'ShuffleIcon',
      // },
      // {
      //   title: 'Memo',
      //   route: 'operations-memo',
      //   icon: 'PaperclipIcon',
      // },
    ],
  },
]
