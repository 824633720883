export default [
  {
    title: 'Operations',
    icon: 'EditIcon',
    children: [
      // {
      //   title: 'Transactions',
      //   route: 'operations-replenishment',
      //   icon: 'InboxIcon',
      // },
      {
        title: 'Supplying',
        route: 'operations-supplying',
        icon: 'LogInIcon',
      },
      // {
      //   title: 'Transfer',
      //   route: 'operations-transfer',
      //   icon: 'ShuffleIcon',
      // },
      // {
      //   title: 'Memo',
      //   route: 'operations-memo',
      //   icon: 'PaperclipIcon',
      // },
    ],
  },
]
