export default [
    {
      header: 'Setups',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'Clients',
          icon: 'LockIcon',
          children: [
            {
              title: 'Account',
              route: 'setups-account',
            },
            {
              title: 'Instansi',
              route: 'setups-instansi',
            }, 
          ],
        },
      ],
    },
  ]
  