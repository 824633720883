export default [
    {
      header: 'Instansi',
      icon: 'PackageIcon',
      title: 'Instansi',
      route: 'instansi',
    },
    // {
    //   header: 'Kalibrasi',
    //   icon: 'ClipboardIcon',
    //   title: 'Kalibrasi',
    //   route: 'kalibrasi',
    // },
  ]
  