<template>
  <div class="handheld-toolbar">
    <div class="d-table w-100">

      <a
        class="d-table-cell handheld-toolbar-item"
        href="#"
      >
        <span class="handheld-toolbar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ><path d="M17 2.1l4 4-4 4" /><path d="M3 12.2v-2a4 4 0 0 1 4-4h12.8M7 21.9l-4-4 4-4" /><path d="M21 11.8v2a4 4 0 0 1-4 4H4.2" /></svg></span>
        <span class="handheld-toolbar-label">Product</span>
      </a>

      <!-- <a
        class="d-table-cell handheld-toolbar-item"
        href="#"
      >
        <span class="handheld-toolbar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ><path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" /></svg>
        </span>
        <span class="handheld-toolbar-label">Scan</span>
      </a> -->

      <a
        class="d-table-cell handheld-toolbar-item"
        href="#"
      >
        <span class="handheld-toolbar-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ><rect
            x="9"
            y="9"
            width="13"
            height="13"
            rx="2"
            ry="2"
          /><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" /></svg></span>
        <span class="badge bg-primary rounded-pill ms-1">4</span>
        <span class="handheld-toolbar-label" />
      </a>
    </div>
  </div>
</template>

<script>


export default {
  components: {

  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css">
.handheld-toolbar {
    display: block;
}
.handheld-toolbar {
    position: fixed;
    backdrop-filter: blur(10px);
    background-color: hsla(0, 0%, 100%, 0.75);
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: -1.25rem 0 2rem 0 rgb(43 52 69 / 15%);
    z-index: 1026;
}
.handheld-toolbar-item {
    padding: 0.5rem;
    padding-top: 0.125rem;
    border-left: 1px solid #e3e9ef;
    text-align: center;
}
.handheld-toolbar-icon {
    display: block;
    color: #000000;
    font-size: 1.25rem;
}
.handheld-toolbar-item:first-child {
    border-right: 0;
}
.rounded-pill {
    border-radius: 50rem !important;
}
a {
    transition: color .25s ease-in-out;
}

</style>
