export default [
  {
    header: 'Setups',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Inventaris', 
        icon: 'PackageIcon',
        children: [
          {
            title: 'Categories',
            route: 'setups-category',
          }, 
          {
            title: 'Unit',
            route: 'setups-unit',
          },
          {
            title: 'Label',
            route: 'setups-label',
          },  
        ],
      },
      {
        title: 'Clients',
        icon: 'LockIcon',
        children: [
          {
            title: 'Account',
            route: 'setups-account',
          }, 
        ],
      },
    ],
  },
]
